import { ClientHint } from "@epic-web/client-hints";
import { debounce } from "lodash-es";

import { useHints } from "~/utils/client-hints";

export const clientHint = {
  cookieName: "CH-is-mobile",
  getValueCode: `window.visualViewport.width`,
  fallback: false,
  transform(value: string) {
    return parseInt(value.toString()) <= 640;
  },
} as const satisfies ClientHint<boolean>;

export function subscribeToMobileChange(
  subscriber: (value: string) => void,
  cookieName: string = clientHint.cookieName,
) {
  const debouncedSubscriber = debounce(subscriber, 100);
  function handleResize() {
    const value = window.innerWidth.toString();
    document.cookie = `${cookieName}=${value}; Max-Age=31536000; Path=/`;
    debouncedSubscriber(value);
  }
  window.addEventListener("resize", handleResize);
  return function cleanupSchemaChange() {
    window.removeEventListener("resize", handleResize);
  };
}

export function useMobileView() {
  const hints = useHints();

  try {
    return clientHint.transform(String(window?.visualViewport?.width));
  } catch (e) {
    if (hints.userAgentIsMobile) {
      return true;
    }
    if (hints?.mobile !== undefined) {
      return hints.mobile;
    }
  }

  return false;
}
